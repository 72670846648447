@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4;
    @apply font-bold;
    @apply text-white;
  }

  button {
    @apply text-white border rounded-md;
  }

  #homeBanner {
    background-image: url("./assets/background-blue.png");
  }

  a:not(.navBar a) {
    @apply font-bold;
    @apply text-blue;
  }

  p {
    @apply leading-8;
  }

  h1 {
    @apply text-[2.5rem];
    @apply font-black;
    @apply text-blue;
  }

  h2 {
    @apply text-2xl;
    @apply font-black;
    @apply text-blue;
  }
}

body {
  font-family: "Montserrat", sans-serif;
}

a.social-icon:hover {
  filter: brightness(0.8);
}

.codingTable {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.codingTable thead tr {
  background-color: #1197ba;
  color: #ffffff;
  text-align: left;
}

.codingTable th,
.codingTable td {
  padding: 12px 15px;
}

.codingTable tbody tr {
  border-bottom: 1px solid #dddddd;
}

.codingTable tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.codingTable tbody tr:last-of-type {
  border-bottom: 2px solid #1197ba;
}

.codingTable tbody tr:hover {
  color: #1197ba;
}

.hideSocials {
  display: none;
}

@media (min-width: 400px) {
  .container {
    max-width: 100%;
  }
}
